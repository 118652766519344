import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Text, Title, cx } from '@therapie-ecommerce-ui/ui';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { LinkButton } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { UpcomingBookings } from '@features/my-account/components/bookings/upcoming-bookings/UpcomingBookings';
import { LoadingContent } from '@components/loading-content/LoadingContent';
import { usePurchasedCoursesStatus } from '@features/booking/hooks/usePurchasedCoursesStatus/usePurchasedCoursesStatus';
import { Card } from '@components/card/Card';
import { shouldRestrictOnlySingleAppointments } from '@features/my-account/components/bookings/upcoming-bookings/UpcomingBookings.utils';
import { ORIGIN_KEY } from '@hooks/useRedirectOrigin/useRedirectOrigin';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import styles from './upcoming-bookings-widget.module.scss';

export const UpcomingBookingsWidget = ({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) => {
  const { t } = useTranslation(['booking', 'common']);
  const router = useRouter();
  const { isWebView } = useIsWebView();
  const isAuthInitialised = useAuthenticationStore((s) => s.isInitialised);
  const isAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);

  const isLoggedOut = isAuthInitialised && !isAuthenticated;

  const {
    isLoading: isLoadingEnrichedUpcomingBookings,
    hasUpcomingBookings,
    hasCoursesWithNoUpcomingBookings,
    hasNoCoursesOrUpcomingBookings,
  } = usePurchasedCoursesStatus();

  return (
    <div className={cx(styles['widget'], className)} {...rest}>
      <LoadingContent.Provider isLoading={isLoadingEnrichedUpcomingBookings}>
        <UpcomingBookings
          shouldHideHeader
          numBookingsShown={3}
          onlySingleAppointment={shouldRestrictOnlySingleAppointments()}
          renderBody={(bookings) => (
            <>
              {hasUpcomingBookings && (
                <Title
                  className={styles['widget__title']}
                  variant="h5"
                  color="night-200"
                  weight="semi-bold"
                >
                  {t('booking:widget.title')}
                </Title>
              )}
              {isLoggedOut && (
                <Text className={styles['widget__message']} variant="h5" color="night-200">
                  {t('booking:widget.loginForBookings')}
                </Text>
              )}
              {hasCoursesWithNoUpcomingBookings && (
                <Text className={styles['widget__message']} variant="h5" color="night-200">
                  {isWebView
                    ? t('booking:widget.hasCoursesWithNoUpcomingBookingsApp')
                    : t('booking:widget.hasCoursesWithNoUpcomingBookings')}
                </Text>
              )}
              {hasNoCoursesOrUpcomingBookings && (
                <Text className={styles['widget__message']} variant="h5" color="night-200">
                  {t('booking:widget.hasNoCoursesOrUpcomingBookings')}
                </Text>
              )}
              {(isLoadingEnrichedUpcomingBookings || hasUpcomingBookings) && bookings}
            </>
          )}
          renderFooter={(footerContent) =>
            isAuthenticated ? (
              footerContent
            ) : isAuthInitialised ? (
              <Card.Footer>
                <LinkButton
                  className={styles['logged-out__link']}
                  href={{ pathname: SITE_URLS.LOGIN, query: { [ORIGIN_KEY]: router?.pathname } }}
                  as={SITE_URLS.LOGIN}
                >
                  {t('common:buttons.logIn')}
                </LinkButton>
              </Card.Footer>
            ) : null
          }
        />
      </LoadingContent.Provider>
    </div>
  );
};
