import { PropsWithChildren } from 'react';

import { createContext, cx, Spinner } from '@therapie-ecommerce-ui/ui';

import {
  LoadingContentConsumerProps,
  LoadingContentProps,
  LoadingContentProviderContext,
  LoadingContentSpinnerProps,
} from './LoadingContent.types';

import styles from './loading-content.module.scss';

const [LoadingContentProvider, useLoadingContentContext] =
  createContext<LoadingContentProviderContext>();

export const LoadingContent = ({
  children,
  className,
  isLoading,
  loadingContent,
  hasContent,
  emptyContent,
}: LoadingContentProps) => {
  if (isLoading) return <>{loadingContent ?? <LoadingContentSpinner className={className} />}</>;

  return <>{!hasContent && emptyContent ? emptyContent : children}</>;
};

const LoadingContentSpinner = ({ className }: LoadingContentSpinnerProps) => (
  <div className={cx(className, styles['loading-container'])}>
    <Spinner color="trp-purple" size="lg" />
  </div>
);

const LoadingContentProviderWithProps = ({
  children,
  ...loadingContentContextValueProps
}: PropsWithChildren<LoadingContentProviderContext>) => (
  <LoadingContentProvider value={loadingContentContextValueProps}>
    {children}
  </LoadingContentProvider>
);

const LoadingContentConsumer = (loadingContentConsumerProps: LoadingContentConsumerProps) => {
  const { isLoading } = useLoadingContentContext();
  return LoadingContent({ isLoading, ...loadingContentConsumerProps });
};

LoadingContent.Consumer = LoadingContentConsumer;
LoadingContent.Provider = LoadingContentProviderWithProps;
