import React from 'react';
import { Title, TitleProps, cx } from '@therapie-ecommerce-ui/ui';
import styles from './accented-title.module.scss';

export const AccentedTitle = ({
  children,
  className,
  ...rest
}: React.PropsWithChildren<TitleProps>) => {
  return (
    <Title
      className={cx(styles['title'], className)}
      variant={{ sm: 'h2', md: 'h1' }}
      weight="bold"
      color="night-400"
      {...rest}
    >
      {children}
    </Title>
  );
};
