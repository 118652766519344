import React from 'react';
import { useTranslation } from 'next-i18next';
import { cx, Skeleton, Text, Title } from '@therapie-ecommerce-ui/ui';
import { Card } from '@components/card/Card';
import { useGetUserLoyaltyPoints } from '@/api/hooks/useGetUserLoyaltyPoints';
import { Price } from '@components/price/Price';
import styles from './available-credit.module.scss';

export const AvailableCredit = ({ className, ...rest }: React.ComponentPropsWithoutRef<'div'>) => {
  const {
    data: loyaltyCredit,
    isLoading: _isLoading,
    isFetching,
    isFetched,
  } = useGetUserLoyaltyPoints();
  const { t } = useTranslation('home');

  const isLoading = _isLoading || isFetching || !isFetched;

  return (
    <Card className={cx(styles['card'], className)} {...rest}>
      <div className={styles['card__points']}>
        <Title color="trp-purple" weight="semi-bold" className={styles['card__title']}>
          {!isLoading ? <Price>{loyaltyCredit ?? 0}</Price> : <Skeleton width={160} />}
        </Title>
        <div className={styles['card__line']} />
      </div>
      <Text color="night-200" weight="semi-bold" variant="h5">
        {t('availableCredit')}
      </Text>
    </Card>
  );
};
