import React from 'react';
import { cx, Icon, SkeletonButton } from '@therapie-ecommerce-ui/ui';
import { LinkButton } from '@components/link/Link';
import { LinkButtonProps } from '@components/link/Link.types';
import { RepeatContent } from '@components/repeat-content/RepeatContent';
import styles from './banner-button.module.scss';
import { LoadingContent } from '@components/loading-content/LoadingContent';

export const BannerButton = ({ children, className, ...rest }: LinkButtonProps) => {
  return (
    <LinkButton
      className={cx(styles['banner-button'], className)}
      variant="outline"
      rightIcon={<Icon family="therapie" name="chevron_right" />}
      isFullWidth
      {...rest}
    >
      {children}
    </LinkButton>
  );
};

const Group = ({
  children,
  className,
  isLoading = false,
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & { isLoading?: boolean }) => {
  return (
    <div className={cx(styles['group'], className)} {...rest}>
      <LoadingContent
        isLoading={isLoading}
        loadingContent={
          <RepeatContent count={2}>
            <BannerButton.Skeleton />
          </RepeatContent>
        }
      >
        {children}
      </LoadingContent>
    </div>
  );
};

const Skeleton = () => <SkeletonButton className={styles['banner-button']} />;

BannerButton.Group = Group;
BannerButton.Skeleton = Skeleton;
