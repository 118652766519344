import React from 'react';
import Head from 'next/head';
import { buildPageTitle } from '@utils/functions/seo';
import { PageTitleProps } from './PageTitle.types';

export const PageTitle = ({ title: titleProp, description }: PageTitleProps) => {
  const title = buildPageTitle(titleProp);

  return (
    <Head>
      <title>{title}</title>
      <meta name={description ? description : title} />
    </Head>
  );
};
