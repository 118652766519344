import { useTranslation } from 'next-i18next';
import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { Skeleton } from '@therapie-ecommerce-ui/ui';
import { UpcomingBookingsWidget } from '@features/home/components/upcoming-bookings-widget/UpcomingBookingsWidget';
import { AccentedTitle } from '@components/accented-title/AccentedTitle';
import { AvailableCredit } from '@features/home/components/available-credit/AvailableCredit';
import { useGetUserQuery } from '@/api/hooks/useGetUserQuery';
import { Container } from '@components/container/Container';
import { BannerButton } from '@features/home/components/banner-button/BannerButton';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { SITE_URLS } from '@utils/constants/site-urls.constants';
import { usePurchasedCoursesStatus } from '@features/booking/hooks/usePurchasedCoursesStatus/usePurchasedCoursesStatus';
import styles from './home-page.module.scss';

export const HomePage = () => {
  const { t } = useTranslation('home');
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery();
  const isUserAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const { isWebView } = useIsWebView();

  const welcomeText = user?.firstName
    ? t('welcomeWithName', { name: user?.firstName })
    : t('welcome');

  return (
    <div className={styles['page']}>
      <Container>
        <div className={styles['page__content']}>
          <AccentedTitle variant="h2" className={styles['page__welcome']}>
            {isLoadingUser ? <Skeleton width={160} /> : welcomeText}
          </AccentedTitle>
          <div>
            {isUserAuthenticated && <AvailableCredit className={styles['page__credit']} />}
            {isWebView && isUserAuthenticated && (
              <div className={styles['page__refer-a-friend']}>
                <BannerButton href={SITE_URLS.REFER_A_FRIEND}>
                  {t('actions.referAFriend')}
                </BannerButton>
              </div>
            )}
            <UpcomingBookingsWidget className={styles['page__upcoming-bookings']} />
            <NavigationActions />
          </div>
        </div>
      </Container>
    </div>
  );
};

const NavigationActions = () => {
  const { isWebView } = useIsWebView();
  const { t } = useTranslation('home');
  const { hasPurchasedCourses } = usePurchasedCoursesStatus();

  if (isWebView) return null;

  return (
    <BannerButton.Group className={styles['actions']}>
      {hasPurchasedCourses && (
        <BannerButton href={SITE_URLS.MY_ACCOUNT_TREATMENTS}>
          {t('actions.bookATreatment')}
        </BannerButton>
      )}
      <BannerButton href={SITE_URLS.TREATMENTS}>{t('actions.purchaseTreatments')}</BannerButton>
      <BannerButton href={SITE_URLS.PRODUCTS}>{t('actions.purchaseProducts')}</BannerButton>
    </BannerButton.Group>
  );
};
