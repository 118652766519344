import React from 'react';
import type { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { dehydrate, QueryClient } from 'react-query';
import { useTranslation } from 'next-i18next';

import { HomePage } from '@features/home/pages/home-page/HomePage';
import { TRANSLATIONS } from '@core/i18n/translations';
import { getDefaultLayout } from '@components/layout/default-layout/DefaultLayout';

import { NextPageWithLayout } from './_app.types';
import { PageTitle } from '@components/page-title/PageTitle';

const Home: NextPageWithLayout = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <PageTitle title={t('therapieClinicHomepage')} />
      <HomePage />
    </>
  );
};

Home.getLayout = getDefaultLayout();

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const queryClient = new QueryClient();

  return {
    props: {
      ...(await serverSideTranslations(locale as string, TRANSLATIONS.home())),
      dehydratedState: dehydrate(queryClient),
    },
  };
}

export default Home;
