import { QueryClient, useQuery } from 'react-query';

import { GetClinicsQuery, GetClinicsQueryVariables } from '@/data/graphql/types';
import { getSdk } from '@/data/graphql/sdk';
import { graphqlGatewayClient } from '@core/graphql/gateway/gateway';
import { graphQLClient } from '@core/graphql/client';

interface UseGetClinicsOptionsProps {
  enabled?: boolean;
}
interface UseGetClinicsByIdOptionsProps extends UseGetClinicsOptionsProps {
  key?: 'crmId' | 'id';
}

const GET_CLINICS_KEY = 'GET_CLINICS';

export const getClinics = async () => {
  const sdk = getSdk(graphQLClient);
  const { getClinics: values } = await sdk.GetClinics();

  return values?.clinics;
};

export function useGetClinicsQuery({ enabled }: UseGetClinicsOptionsProps = {}) {
  return useQuery(
    GET_CLINICS_KEY,
    async () => {
      const { getClinics: values } = await graphqlGatewayClient.post<
        GetClinicsQueryVariables,
        GetClinicsQuery
      >('/GetClinics');

      return values?.clinics;
    },
    { enabled }
  );
}

export const useGetClinicsByIdQuery = (
  clinicId: string,
  options: UseGetClinicsByIdOptionsProps = {}
) => {
  const { key = 'crmId', enabled } = options;
  const { data, ...restProps } = useGetClinicsQuery({ enabled });

  return {
    data: data?.find((clinic) => clinic?.[key] === clinicId),
    ...restProps,
  };
};

export const prefetchGetClinicsQuery = (queryClient: QueryClient) => {
  return queryClient.prefetchQuery(GET_CLINICS_KEY, getClinics);
};
