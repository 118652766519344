import { useAuthenticationStore } from '@therapie-ecommerce-ui/auth';
import { useGetSplitUserBookingsHistoryQuery } from '@features/booking/api/hooks/useGetUserBookingsHistoryQuery';
import { useGetUserTreatmentsQuery } from '@features/booking/api/hooks/useGetUserTreatmentsQuery';

export const usePurchasedCoursesStatus = () => {
  const {
    isLoading: isLoadingUpcomingBookings,
    isFetched: isFetchedUpcomingBookings,
    data: { upcomingBookings },
  } = useGetSplitUserBookingsHistoryQuery();
  const {
    data: userTreatments,
    isLoading: isLoadingUserTreatments,
    isFetched: isFetchedUserTreatments,
  } = useGetUserTreatmentsQuery();

  const isAuthInitialised = useAuthenticationStore((s) => s.isInitialised);
  const isAuthenticated = useAuthenticationStore((s) => s.isUserAuthenticated);
  const isLoggedOut = isAuthInitialised && !isAuthenticated;
  const isFetched = isFetchedUpcomingBookings && isFetchedUserTreatments;
  const isLoading =
    isAuthenticated && (isLoadingUpcomingBookings || isLoadingUserTreatments || !isAuthInitialised);

  const hasPurchasedCourses = isAuthenticated && userTreatments && userTreatments?.length > 0;
  const hasUpcomingBookings = isAuthenticated && upcomingBookings?.length > 0;

  const hasCoursesWithNoUpcomingBookings =
    isAuthenticated && isFetched && hasPurchasedCourses && !hasUpcomingBookings;
  const hasNoCoursesOrUpcomingBookings =
    isAuthenticated && isFetched && !hasPurchasedCourses && !hasUpcomingBookings;

  return {
    isLoading,
    isFetched,
    isLoggedOut,
    hasUpcomingBookings,
    hasCoursesWithNoUpcomingBookings,
    hasNoCoursesOrUpcomingBookings,
    hasPurchasedCourses,
  };
};
